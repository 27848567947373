<template>
    <div class="col-md-7">
        <seccion-datos titulo="Correo" tipo="warning" @cambios="guardar(id)">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $t('general.usuario') }}</label>
                        <input type="text" class="form-control  dato" v-model="usuario_email_empresa">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $t('general.clave') }}</label>
                        <input type="password" class="form-control  dato" v-model="clave_email_empresa">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-7">
                    <div class="form-group">
                        <label>{{ $t('general.remitente') }}</label>
                        <input type="text" class="form-control  dato" v-model="remitente_email_empresa">
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                        <label>{{ $t('general.emailempresa') }}</label>
                        <select class="form-control select">
                            <option value="">Por defecto</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $t('general.servidorimap') }}</label>
                        <input type="text" class="form-control  dato" v-model="servidor_entrante_empresa">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $t('general.servidorsmtp') }}</label>
                        <input type="text" class="form-control  dato" v-model="servidor_saliente_empresa">
                    </div>
                </div>
            </div>
        </seccion-datos>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id'],
    data() {
        return {
            empresa: '',
            usuario_email_empresa: '',
            clave_email_empresa: '',
            remitente_email_empresa: '',
            servidor_entrante_empresa: '',
            servidor_saliente_empresa: '',
            email_empresa: '',
            seleccionado: '',
        }
    },
    methods: {
        async obtenerEmpresa(id) {
            const api = new PwgsApi;
            const datos = await api.get('empresas/' + id);
            this.empresa = datos.datos;
            this.$store.dispatch('cambiarCargando', true);
            this.usuario_email_empresa = this.empresa.usuario_email_empresa;
            this.clave_email_empresa = this.empresa.clave_email_empresa;
            this.remitente_email_empresa = this.empresa.remitente_email_empresa;
            this.email_empresa = this.empresa.email_empresa
            this.servidor_entrante_empresa = this.empresa.servidor_entrante_empresa;
            this.servidor_saliente_empresa = this.empresa.servidor_saliente_empresa;
            this.$store.dispatch('cambiarCargando', false);
            this.datosempresa = this.$t('general.datosempresa');
        },
        async guardar(id) {
            /* put  modulos/pwgsapi/index.php/lineas-facturacion/:id   */
            const subidadatos = { usuario_email_empresa: this.usuario_email_empresa, clave_email_empresa: this.clave_email_empresa, remitente_email_empresa: this.remitente_email_empresa, servidor_entrante_empresa: this.servidor_entrante_empresa, servidor_saliente_empresa: this.servidor_saliente_empresa, email_empresa: this.email_empresa };
            const api = new PwgsApi;
            if (this.id > 0) {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('empresas/' + this.id, subidadatos);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.obtenerEmpresa(id);
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
                /* post  modulos/pwgsapi/index.php/lineas-facturacion/  */
                try {
                    let res;
                    res = await api.post('empresas/', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$router.push('/empresas/' + res.id);
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
        },
    },
    mounted() {

        this.obtenerEmpresa(this.id);
    },
    watch: {
        id() {
            this.obtenerEmpresa(this.id);
        }
    }
}
</script>